/** @format */

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectedShowFlowPanel } from "../../redux/reducers/CommonSlice/commonSliceSelectors";
import { resetPairForm } from "../../redux/reducers/PairFormSlice/pairFormSlice";
import FlowPanel from "../FlowPanel";
import PairFlow from "../FlowPanel/PairFlow";
import { IFlowPanel } from "../FlowPanel/interfaces";
import SnackbarWrapper from "../Snackbar";
import TerminalList from "../TerminalsPage/TerminalsPage";
import PairForm from "./PairForm";
import PairStatus from "./PairStatus";
import useStyles from "./index.styles";

const PairPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const showFlowPanel = useAppSelector(selectedShowFlowPanel);
  const classes = useStyles(showFlowPanel as unknown as IFlowPanel);
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const acuirerCodeFromParam = queryParams.get("acquirerCode");
  const serialNumberFromParam = queryParams.get("serialNumber");
  const posIdFromParam = queryParams.get("posId");
  const [isViewTerminalOpen, setIsViewTerminalOpen] = useState(false);
  const [isPairPageOpen, setisPairPageOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!acuirerCodeFromParam || !serialNumberFromParam || !posIdFromParam) {
      dispatch(resetPairForm());
    } // reset paired form when required
    setisPairPageOpen(true);
  }, [dispatch, state]);

  return (
    // <Layout>
    // <Modal
    //   backdrop="static"
    //   show={isPairPageOpen}
    //   size="lg"
    //   className="pay-now-modal-pos"
    //   backdropClassName="customer-backdrop"
    //   centered
    //   onHide={() => setisPairPageOpen(false)}
    // >
    //   <Modal.Body>
    <div>
      <div className="d-flex justify-content-between mt-3 ms-3 me-3">
        {/* <h5>Pair Now</h5> */}
        {/* <CloseOutlined
          onClick={() => {
            navigate("/isWebClosed/?isWebClosed=true");
          }}
          style={{
            color: "red",
            border: "1px solid red",
            borderRadius: "50%",
          }}
        /> */}
      </div>
      <div className="row justify-content-end">
        <Button
          onClick={() => {
            setIsViewTerminalOpen(true);
          }}
          type="primary"
          className="w-20"
          style={{ marginRight: "2rem" }}
        >
          View Paired Terminals
        </Button>
      </div>
      <Drawer
        title="Terminals"
        width={"968"}
        key={"right"}
        open={isViewTerminalOpen}
        onClose={() => {
          setIsViewTerminalOpen(false);
        }}
        mask={false}
      >
        <TerminalList />
      </Drawer>
      <div className={classes.root}>
        <div
          className={
            showFlowPanel
              ? `${classes.content} ${classes.contentShift}`
              : classes.content
          }
        >
          <Container>
            <Grid container>
              <Grid item sm={8} xs={12} className={classes.pairFormContainer}>
                <PairForm />
              </Grid>
              <Grid item sm={4} xs={12} className={classes.pairStatusContainer}>
                <PairStatus />
              </Grid>
            </Grid>
          </Container>
        </div>
        <FlowPanel>
          <PairFlow />
        </FlowPanel>
        <SnackbarWrapper />
      </div>
    </div>
    //   </Modal.Body>
    // </Modal>

    // </Layout>
  );
};

export default PairPage;
