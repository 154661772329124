/** @format */

import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "../../Redux/rootEpics";
import spiService from "../services/spiService";
import { getLocalStorage } from "../utils/common/spi/common";
import commonReducer from "./reducers/CommonSlice/commonSlice";
import pairFormReducer from "./reducers/PairFormSlice/pairFormSlice";
import productSlice from "./reducers/ProductSlice/productSlice";
import selectedTerminalReducer from "./reducers/SelectedTerminalSlice/selectedTerminalSlice";
import terminalReducer from "./reducers/TerminalSlice/terminalsSlice";

/// POS Reducers

import posDeviceSettingsReducer from "../../Redux/reducers/posDeviceSettingsReducer";

const recordedTerminals = getLocalStorage("terminals");
const persistedState = JSON.parse(recordedTerminals || "{}");

const epicMiddleware = createEpicMiddleware();
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: process.env.NODE_ENV === "development",
    }).concat(epicMiddleware),
  reducer: {
    common: commonReducer,
    pairForm: pairFormReducer,
    products: productSlice,
    terminals: terminalReducer,
    selectedTerminal: selectedTerminalReducer,

    /// POS REducers ------

    posDeviceSettingsReducer,
  },
  preloadedState: persistedState,
  devTools: process.env.NODE_ENV === "development",
});
epicMiddleware.run(rootEpic);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

spiService.start(store.dispatch);
