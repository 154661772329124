/** @format */

import { combineEpics } from "redux-observable";

import {
  getAllPairingConfigurationSectionEpic,
  getReleaseVersionEpic,
} from "./posDeviceSettingsEpic";

export const rootEpic = combineEpics(
  getAllPairingConfigurationSectionEpic,
  getReleaseVersionEpic
);
