/** @format */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isPaired } from "../../mx51/redux/reducers/TerminalSlice/terminalsSliceSelectors";

const HasPairing = () => {
  const isTerminalPaired = useSelector(isPaired);
  const navigate = useNavigate();

  useEffect(() => {
    if (isTerminalPaired) {
      navigate("/posDevice/?hasPairing=true");
    } else {
      navigate("posDevice/?hasPairing=false");
    }
  }, []);
  return <div></div>;
};

export default HasPairing;
