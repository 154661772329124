/** @format */

// eslint-disable-next-line import/prefer-default-export

interface LastTransactionData {
  instanceId: string;
  posRefId: string;
  transactionType: string;
  orderRequest: any;
}

export function serialNumberFormatter(currentSerialNumber: string): string {
  let formatSerialNumber = currentSerialNumber.slice(0, 11).replace(/-/g, "");

  if (formatSerialNumber.length > 3 && formatSerialNumber.length <= 6)
    formatSerialNumber = `${formatSerialNumber.slice(
      0,
      3
    )}-${formatSerialNumber.slice(3)}`;
  else if (currentSerialNumber.length > 6)
    formatSerialNumber = `${formatSerialNumber.slice(
      0,
      3
    )}-${formatSerialNumber.slice(3, 6)}-${formatSerialNumber.slice(6)}`;

  return formatSerialNumber;
}

export const setLastTransactionData = (data: LastTransactionData): void => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem("last-transaction", serializedData);
  } catch (error) {
    console.error("Error storing data in local storage:", error);
  }
};
export const getLastTransactionData = (): LastTransactionData | null => {
  try {
    const serializedData = localStorage.getItem("last-transaction");
    if (serializedData) {
      const parsedData = JSON.parse(serializedData);
      return parsedData;
    }
  } catch (error) {
    console.error("Error retrieving data from local storage:", error);
  }
  return null;
};
export const removeLastTransactionData = (): void => {
  try {
    localStorage.removeItem("last-transaction");
  } catch (error) {
    console.error("Error removing data from local storage:", error);
  }
};
export const extractKeyValueFromMerchantReciept = (currentTerminal: any) => {
  const pattern = /(\w+)\s+([^\n]+)/g;

  const keyValueData = {};
  let match;
  while (
    (match = pattern.exec(
      currentTerminal?.txFlow?.response?.data.merchantReceipt
    )) !== null
  ) {
    const key = match[1].trim();
    const value = match[2].trim();
    keyValueData[key] = value;
  }
  return keyValueData;
};
