/** @format */

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SPI_TRANSACTION_TYPES } from "../../definitions/constants/commonConfigs";
import { TEXT_CASHOUT } from "../../definitions/constants/routerConfigs";
import { useAppSelector } from "../../redux/hooks";
import selectedTerminalIdSelector from "../../redux/reducers/SelectedTerminalSlice/selectedTerminalSliceSelector";
import { ITerminalProps } from "../../redux/reducers/TerminalSlice/interfaces";
import {
  terminalInstance,
  terminalTxFlowAwaitingSignatureTracker,
  terminalTxMessage,
} from "../../redux/reducers/TerminalSlice/terminalsSliceSelectors";
import {
  approveSignature,
  declineSignature,
} from "../../utils/common/terminal/terminalHelpers";
import useStyles from "./index.styles";
import { TransactionProgressModalProps } from "./interfaces";

function TransactionProgressModal({
  terminalId,
  transactionType,
  transactionDesc,
  isFinished,
  isSuccess,
  onCancelTransaction,
  onRetryTransaction,
  posRefId,
  onDone,
}: TransactionProgressModalProps): React.ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const amountFromParam = queryParams.get("amount");
  const surchargeAmount = queryParams.get("surchargeAmount");
  const tipAmount = queryParams.get("tipAmount");
  const TransactionType = queryParams.get("transactionType");
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setTimeout(() => {
      setOnline(true);
    }, 5000);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });
  const awaitingSignatureCheck = useAppSelector(
    terminalTxFlowAwaitingSignatureTracker(terminalId)
  );
  const selectedTerminal = useSelector(selectedTerminalIdSelector);
  const currentTerminal = useSelector(
    terminalInstance(selectedTerminal)
  ) as ITerminalProps;
  const modalTitle =
    transactionType === SPI_TRANSACTION_TYPES.CashoutOnly
      ? TEXT_CASHOUT.toUpperCase()
      : transactionType.toUpperCase();

  const txMessage = useAppSelector(terminalTxMessage(terminalId));

  const handleApprove = () => {
    approveSignature(terminalId);
  };

  const handleDecline = () => {
    declineSignature(terminalId);
  };
  const merchantReceipt = btoa(
    currentTerminal?.txFlow?.response?.data?.merchantReceipt
  );

  const customerReceipt = btoa(
    currentTerminal?.txFlow?.response?.data?.customerReceipt
  );
  useEffect(() => {
    if (isSuccess && isFinished) {
      navigate(
        `/?isPaymentSuccess=true&posRefId=${posRefId}&merchantReceipt=${merchantReceipt}&customerReceipt=${customerReceipt}&amount=${amountFromParam}&transactionType=${TransactionType}&surchargeAmount=${
          surchargeAmount ?? ""
        }&tipAmount=${tipAmount ?? ""}`
      );
    }
  }, [isFinished, isSuccess]);
  const signatureString = btoa(
    currentTerminal?.txFlow?.signatureRequiredMessage?._receiptToSign
  );

  useEffect(() => {
    if (awaitingSignatureCheck) {
      navigate(
        `/?printSignature=true&signatureString=${signatureString}&amount=${amountFromParam}&transactionType=${TransactionType}&surchargeAmount=${
          surchargeAmount ?? ""
        }&tipAmount=${tipAmount ?? ""}`
      );
    }
  }, [awaitingSignatureCheck]);

  useEffect(() => {
    if (
      isFinished &&
      !isSuccess &&
      currentTerminal?.txFlow?.response?.data.merchantReceipt
    ) {
      navigate(
        `/?isPaymentSuccess=false&posRefId=${posRefId}&merchantReceipt=${merchantReceipt}&customerReceipt=${customerReceipt}&amount=${amountFromParam}&transactionType=${TransactionType}&surchargeAmount=${
          surchargeAmount ?? ""
        }&tipAmount=${tipAmount ?? ""}`
      );
    }
  }, [isSuccess, isFinished]);

  return (
    <Dialog aria-labelledby="spiModalTitle" open disableRestoreFocus>
      {isOnline ? (
        <div>
          {awaitingSignatureCheck ? (
            <>
              <DialogTitle>
                <IconButton
                  onClick={handleDecline}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box className={classes.signatureFlow}>
                  <Result
                    status="warning"
                    title="Confirm customer signature !"
                    subTitle=" Does the customer&#39;s signature match the signature on the
                    card?"
                  />
                  <Button
                    color="primary"
                    data-test-id="approveSignature"
                    onClick={handleApprove}
                  >
                    Yes
                  </Button>
                  <Button
                    color="primary"
                    data-test-id="declineSignature"
                    onClick={handleDecline}
                  >
                    No
                  </Button>
                </Box>
              </DialogContent>
            </>
          ) : (
            <>
              {/* <DialogTitle>
                <IconButton
                  onClick={() => {
                    navigate("/isWebClosed/?isWebClosed=true");
                  }}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle> */}
              <DialogContent
                className={classes.transactionProgressModalContent}
              >
                {!isFinished && (
                  <>
                    <Spin size="default"></Spin>

                    <Typography
                      variant="body2"
                      className={classes.modalHeading}
                    >
                      {transactionType.toUpperCase()}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.modalSubHeading}
                    >
                      <div> In progress </div>
                      <Typography className={classes.modalDescription}>
                        {" "}
                        {txMessage?.displayMessageText}
                      </Typography>
                    </Typography>
                  </>
                )}

                {isFinished && !isSuccess && (
                  <>
                    <Result status={"error"} />

                    <Typography
                      variant="body2"
                      className={classes.modalHeading}
                    >
                      {modalTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.modalSubHeading}
                    >
                      Declined
                    </Typography>
                    <Typography className={classes.modalDescription}>
                      {transactionDesc}
                    </Typography>
                  </>
                )}
                {!isFinished && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      onCancelTransaction();
                      navigate(
                        `/?amount=${amountFromParam}&transactionType=${TransactionType}&isTransactionCancel=true&&surchargeAmount=${
                          surchargeAmount ?? ""
                        }&tipAmount=${tipAmount ?? ""}`
                      );
                    }}
                    className={classes.modalBtn}
                  >
                    Cancel transaction
                  </Button>
                )}

                {isFinished && !isSuccess && (
                  <Box display="flex" justifyContent="space-evenly">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        onRetryTransaction();
                        navigate(
                          `/?amount=${amountFromParam}&transactionType=${TransactionType}&surchargeAmount=${
                            surchargeAmount ?? ""
                          }&tipAmount=${tipAmount ?? ""}`
                        );
                      }}
                      className={`${classes.modalBtn} ${classes.btnWithLeftMargin}`}
                    >
                      Retry
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      // to={"/pos"}
                      onClick={() => {
                        navigate("/isWebClosed/?isWebClosed=true");
                      }}
                      className={`${classes.modalBtn} ms-2`}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </DialogContent>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            margin: "20px",
            background: "white",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <>
            <Result
              status="warning"
              title="Connection Lost !"
              subTitle="POS lost connection. Once reconnected to terminal, transaction continues. Or You can come back later to recover this transaction."
            />
          </>
        </div>
      )}
    </Dialog>
  );
}

export default TransactionProgressModal;
