/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MessageProvider } from "./helpers/MessageProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MessageProvider>
    <App />
  </MessageProvider>
);

serviceWorkerRegistration.register();
